<div
  class="data-form-container container animate__animated animate__fadeInDown"
>
  <h1 class="title-form mat-typography">Inserisci i tuoi dati</h1>
  <mat-vertical-stepper linear #stepper>
    <mat-step [stepControl]="infoPersona">
      <form [formGroup]="infoPersona" (ngSubmit)="termsVisible()">
        <ng-template matStepLabel>Informazioni d'identità</ng-template>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>Nome</mat-label>
          <input
            formControlName="nome"
            matInput
            placeholder="Es: Carlo, Mario, etc."
            required
            name="Nome"
          />
          <mat-error *ngIf="!infoPersona.get('nome')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>Cognome</mat-label>
          <input
            formControlName="cognome"
            matInput
            required
            placeholder="Es: Bianchi, Rossi, etc."
            name="Cognome"
          />
          <mat-error *ngIf="!infoPersona.get('cognome')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>C.F.</mat-label>
          <input
            formControlName="CF"
            matInput
            required
            placeholder="Codice Fiscale"
            name="CodFisc"
          />
          <mat-error *ngIf="!infoPersona.get('CF')?.valid">{{
            getCFError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>E-mail</mat-label>
          <input
            formControlName="email"
            matInput
            required
            placeholder="es: nomeutente@dominio.it"
            name="E-mail"
          />
          <mat-error *ngIf="!infoPersona.get('email')?.valid">{{
            getEmailError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>P.IVA</mat-label>
          <input
            formControlName="pIVA"
            matInput
            required
            placeholder="Partita IVA"
            name="pIva"
          />
          <mat-error *ngIf="!infoPersona.get('pIVA')?.valid">{{
            getpIVAError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>PEC o Codice Univoco</mat-label>
          <input
            formControlName="pecCu"
            matInput
            required
            placeholder="Pec o Codice Univoco"
            id="pecCu"
            name="PecCu"
          />
          <mat-error *ngIf="!infoPersona.get('pecCu')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <p class="mat-typography" id="terms">
          <mat-checkbox
            formControlName="terms"
            color="primary"
            (click)="termsHidden()"
          >
            <span>
              Accetto
              <a class="bg-white" target="_blank" href="./privacy.html"
                >termini e condizioni</a
              >
            </span>
          </mat-checkbox>
          <br />
          <span id="termini" color="warn" style="visibility: hidden"
            >Devi accettare i termini di servizio per continuare</span
          >
        </p>
        <p class="send-btn">
          <button
            mat-raised-button
            matStepperNext
            color="primary"
            (click)="mailLCN()"
          >
            Invia
          </button>
        </p>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>
<span *ngIf="completamentoOperazione"
  ><app-loading-result
    [completo]="completo"
    [errore]="errore"
    [purchase]="false"
  ></app-loading-result
></span>
