import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MailSenderService } from 'src/app/mail-sender.service';

const require = "Campo richiesto";

@Component({
  selector: 'app-form-lcn',
  templateUrl: './form-lcn.component.html',
  styleUrls: ['./form-lcn.component.scss']
})
export class FormLcnComponent implements OnInit {

  completamentoOperazione = false;
  completo = false;
  errore = false;
  infoPersona = this.fb.group({
    nome : ['', Validators.required],
    cognome : ['', Validators.required],
    CF : ['', [Validators.required, Validators.pattern("^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$")]],
    email : ['', [Validators.required, Validators.email]],
    pIVA : ['', [Validators.required, Validators.pattern("^[0-9]{11}$")]],
    pecCu : ['', Validators.required],
    terms : ['', Validators.requiredTrue]
  });

  constructor(private fb: FormBuilder, private mailer: MailSenderService) { }

  ngOnInit(): void {
  }

  getEmailError(){
    if(this.infoPersona.get("email")?.hasError("email")){
      return "Formato email non corretto";
    } else if(this.infoPersona.get("email")?.hasError){
      return require;
    }
    return
  }

  getRequiredError(){
    return require;
  }

  getCFError(){
    if(this.infoPersona.get("CF")?.hasError("required")){
      return require;
    } else if(this.infoPersona.get("CF")?.hasError){
      return "Formato non valido"
    }
    return
  }

  getpIVAError(){
    if(this.infoPersona.get("pIVA")?.hasError("required")){
      return require;
    } else if(this.infoPersona.get("pIVA")?.hasError){
      return "Il campo deve contenere esattamente 11 caratteri numerici";
    }
    return
  }

  termsVisible(){
    if(this.infoPersona.get("terms")?.hasError("required")){
      document.getElementById("termini")?.setAttribute("style", "visibility: visible");
    }
  }

  termsHidden(){
    document.getElementById("termini")?.setAttribute("style", "visibility: hidden");
    this.infoPersona.get("terms")?.touched
  }

  mailLCN(){
    if (this.infoPersona.invalid){
      return
    }
    this.completamentoOperazione = true;  
    var data = JSON.stringify({"nome": this.infoPersona.get("nome")?.value,
                              "cognome": this.infoPersona.get("cognome")?.value,
                              "CF": this.infoPersona.get("CF")?.value,
                              "email": this.infoPersona.get("email")?.value,
                              });                  
    var response = this.mailer.lcn(data);
    response.subscribe(x => {
      this.resultControl(x)
    },
    error => {
      this.completo = true;
      this.errore = true;
      console.log(error);
    })                   
  }

  resultControl(x : Object){
    if(x == "inviato"){
      this.completamentoOperazione = true;
      this.completo = true;
    } else {
      this.completo = true;
      this.errore = true;
      console.log(x);
    }
  }
}
