<section id="descrizione-gps" class="mat-typography">
    <div class="col-md-12">
        <div class="row" style="overflow: hidden;">
            <div class=" col-sm-12 col-md-6 text-box-gps" data-aos="slide-right" data-aos-duration="1500" data-aos-offset="500">
                <p class="text-descrizione-gps">
                    Grazie all’utilizzo del gps, ovunque essa sia, comunicherà la sua posizione. <br><br>
                    Tale posizione verrà poi inviata utilizzando la rete dati mobile alla nostra centrale operativa e potrete visualizzarla sulla mappa interattiva. <br><br>
                    Se la rete dati non funzionasse per assenza di linea, i dati verranno memorizzati ed inviati appena la rete sarà disponibile. <br><br>
                    Il nostro prodotto funziona anche in roaming e quindi aggancerà la rete mobile anche fuori dai confini del nostro paese.
                </p>
            </div>
            <div class="container col-sm-12 col-md-6">
                
            </div>
        </div>
    </div>
</section>
