import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomePageComponent } from './home-page/home-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PresentationComponent } from './home-page/presentation/presentation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShoppingPageComponent } from './shopping-page/shopping-page.component';
import { FormDataCustomerComponent } from './shopping-page/form-data-customer/form-data-customer.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { InfoPageComponent } from './info-page/info-page.component';
import { FormInfoComponent } from './info-page/form-info/form-info.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FooterComponent } from './footer/footer.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingResultComponent } from './loading-result/loading-result.component';
import { DescrizioneProdottoComponent } from './home-page/descrizione-prodotto/descrizione-prodotto.component';
import { DescrizioneGpsComponent } from './home-page/descrizione-gps/descrizione-gps.component';
import { ChiSiamoComponent } from './home-page/chi-siamo/chi-siamo.component';
import { CaratteristicheProdottoComponent } from './home-page/caratteristiche-prodotto/caratteristiche-prodotto.component';
import { CookiePopupComponent } from './cookie-popup/cookie-popup.component';
import { LavoraConNoiComponent } from './lavora-con-noi/lavora-con-noi.component';
import { FormLcnComponent } from './lavora-con-noi/form-lcn/form-lcn.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SezioneArteComponent } from './home-page/sezione-arte/sezione-arte.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { TutorialPageComponent } from './tutorial-page/tutorial-page.component';
import { NgxStripeModule } from 'ngx-stripe';
import { PrezziComponent } from './home-page/prezzi/prezzi.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    PresentationComponent,
    ShoppingPageComponent,
    FormDataCustomerComponent,
    InfoPageComponent,
    FormInfoComponent,
    FooterComponent,
    PrivacyComponent,
    LoadingResultComponent,
    DescrizioneProdottoComponent,
    DescrizioneGpsComponent,
    ChiSiamoComponent,
    CaratteristicheProdottoComponent,
    CookiePopupComponent,
    LavoraConNoiComponent,
    FormLcnComponent,
    SezioneArteComponent,
    ContactPageComponent,
    TutorialPageComponent,
    PrezziComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NgbModule,
    MatSelectModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatSidenavModule,
    MatListModule,
    NgxPayPalModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    NgxStripeModule.forRoot(
      'pk_live_51OUBWQHZpAnzUHfnINTCwSqGkqkAQqNUYJbQOfVou8zRcI0njA9qIipGPIQYyuvQ0mvsHGbqp3bMx3sgQoNauNsi00NsLfgspE'
    ),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
