import { Component, OnInit} from '@angular/core';
import{Router, NavigationEnd} from '@angular/router';
import * as AOS from 'aos';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'boat-tracker';
  

  ngOnInit(){
    AOS.init();
  }
  scroll(id: string){
    setTimeout(() => {
      const elmnt = document.getElementById(id);
      elmnt?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 50);  
  }

  scrollToTop(){
    window.scrollTo(0,0);
  }

  /*constructor(public router: Router){
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd && this.router.navigated){
        gtag('config', 'G-8DX0X2KXTG',
          {
            'page_path': event.urlAfterRedirects
          });
      }
    })
  }*/
}
