<div class=" mat-typography">
    <div class="content" style="padding-top: 150px;">
        <div class="container sales-managers" data-aos="slide-right" data-aos-duration="1500">
            <h1 class="boa">Sales Managers</h1>
            <hr class="">
            <div class="manager">
                <h2 class="">Gestione Sicilia Orientale:</h2>
                <h3 class="info ">Giuseppe Luca Garozzo</h3>
                <h3 class="info ">E-mail: <a class="boa" href="mailto:xlukas2rx@gmail.com">xlukas2rx@gmail.com</a></h3>
                <h3 class="info ">Tel: +39 328 824 1976</h3>
            </div>
            <hr class="">
            <div class="manager">
                <h2 class="">Gestione Sicilia Occidentale:</h2>
                <h3 class="info">Stefano Sciacca</h3>
                <h3 class="info">E-mail: <a class="boa" href="mailto:stefano.sciacca75@gmail.com">stefano.sciacca75@gmail.com</a></h3>
                <h3 class="info">Tel: +39 393 865 2666</h3>
            </div>
        </div>
    </div>
</div>