<section class="hero" id="home">
  <div class="presentation-content" data-aos="slide-down" data-aos-duration="1500">
    <div class="container">
      <div class="row">
        <!--<div class="hero-text white-text mat-typography  col-md-12" style="padding-bottom: 25px; text-align: center;">
          <img rel="preload" src="./assets/images/BSEASAT.png" alt="" width="350px" class="logo-presentation">
          <br>
          <p class="white-text" style="margin-top: 30px;">
            L'antifurto satellitare pensato per le imbarcazioni, che permette di individuare la posizione e vi avvisa in tempo reale ad ogni suo spostamento. 
          </p>
        </div>-->
        <div class="image-bg">
          <div class="text-presentation prototype primary">
            <p class="medium-size boa">L'ALLARME SATELLITARE A BATTERIA</p>
            <p class="medium-size">pensato per le imbarcazioni,</p>
            <p class="medium-size">che permette di individuare la posizione,</p>
            <p class="medium-size">vi avvisa in tempo reale ad ogni suo spostamento e</p>
            <p class="medium-size boa">NON NECESSITA DI INSTALLAZIONE</p>      
          </div>
        </div>
      </div>
    </div>
  </div> 
</section>
