<section id="descrizione">
    <div class="col-md-12">
        <div class="row mat-typography" style="overflow: hidden;">
            <div class="container col-md-6">
            </div>
            <div class=" container col-sm-12 col-md-6 text-box-descrizione" data-aos="slide-left" data-aos-duration="1500" data-aos-offset="500">
                <p class="text-descrizione" >
                    Un’imbarcazione ha diversi scopi, viene usata per viaggiare, divertirsi o lavorare.  <br><br>
                    Il nostro prodotto può essere posizionato su qualsiasi tipo di imbarcazione ed è perfetto per controllare tutti gli spostamenti.
                </p>
            </div>
        </div>
    </div>
</section>
