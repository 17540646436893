import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-popup',
  templateUrl: './cookie-popup.component.html',
  styleUrls: ['./cookie-popup.component.scss'],
})
export class CookiePopupComponent implements OnInit {
  cookie = document.cookie;

  constructor() {}

  ngOnInit(): void {}

  setCookie() {
    let random = this.rand() + this.rand();
    this.scriviCookie('user', random);
    this.cookie = document.cookie;
    window.location.reload();
  }

  rand() {
    return Math.random().toString(36).substr(2); // remove `0.`
  }

  scriviCookie(nomeCookie: string, valoreCookie: string) {
    var scadenza = new Date();
    var adesso = new Date();
    scadenza.setMonth(adesso.getMonth() + 24);
    document.cookie =
      nomeCookie +
      '=' +
      escape(valoreCookie) +
      '; expires=' +
      scadenza.toUTCString() +
      '; path=/';
  }
}
