<footer>
  <div class="container">
    <div class="row">
      <div class="footer-info col-md-6">
        <p>E-mail: info@bseasat.com</p>
        <p style="font-size: small">P.IVA 05047840870</p>
        <p type="tel">Tel: +39 3501457587</p>
        <p><br /></p>
        <span>Paintings by </span><a class="text-white">Giovanni Trischitta</a
        ><br />
        <span
          >Photo by
          <a
            class="text-white"
            href="https://unsplash.com/@chrislawton?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >Chris Lawton</a
          >,
          <a
            class="text-white"
            href="https://unsplash.com/@evansmogor?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >Evan Smogor</a
          >,
          <a
            class="text-white"
            href="https://unsplash.com/@karsten116?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >Karsten Winegeart</a
          >,
          <a
            class="text-white"
            href="https://unsplash.com/@slgoetz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >Simon Goetz</a
          >,
          <a
            class="text-white"
            href="https://unsplash.com/@steve_j?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >Steve Johnson</a
          >
          <a
            class="text-white"
            href="https://unsplash.com/@nasa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >NASA</a
          >
          and
        </span>
        <span
          ><a
            class="text-white"
            href="https://unsplash.com/@wizwow?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
            >Donald Giannatti</a
          >
          on
          <a
            class="text-white"
            href="https://unsplash.com/s/photos/beekeeper?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText"
            >Unsplash</a
          ></span
        >
        <br />
        <a
          class="text-white"
          href="https://www.flaticon.com/free-icons/sold-out"
          title="sold out icons"
          >Sold out icons created by Freepik - Flaticon</a
        >
      </div>
      <div class="footer-social col-md-6">
        <p>Seguici su:</p>
        <a
          target="_blank"
          href="https://www.facebook.com/Bseasat-106346134859465"
        >
          <div class="logo-social fb"></div>
        </a>
        <a target="_blank" href="https://www.instagram.com/bseasat/">
          <div class="logo-social insta"></div>
        </a>
      </div>
    </div>
  </div>
</footer>
