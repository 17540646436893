<div
  id="cookie-popup"
  *ngIf="cookie == ''"
  data-aos="fade-up"
  data-aos-duration="1000"
>
  <div class="container">
    <div class="row">
      <div class="col-xl-6">
        <span
          >Questo sito utilizza cookie propri al fine di migliorare il
          funzionamento e monitorare le prestazioni del sito web e/o cookie
          derivati da strumenti esterni che consentono di inviare messaggi
          pubblicitari in linea con le tue preferenze, come dettagliato nella
          <a href="./privacy.html">Privacy Policy</a>. Per continuare è
          necessario autorizzare i nostri cookie. Cliccando sul pulsante
          ACCONSENTO, acconsenti all'uso dei cookie.
        </span>
      </div>
      <div class="col-xl-6 btn-div">
        <button
          mat-raised-button
          style="margin-top: 30px"
          id="cookie-accept"
          (click)="setCookie()"
        >
          Acconsento
        </button>
      </div>
    </div>
  </div>
</div>
