<section id="sezione-arte">
    <div class="col-md-12">
        <div class="row mat-typography" style="overflow: hidden;">
            <div class="container col-sm-12 col-md-6">
               <!-- <div class="sposta-text">
                    <p class="title">LA NOSTRA ARTE</p>
                    <br>
                    <p class="font-xl">L'artista Giovanni Trischitta ha creato per noi</p>
                    <p class="font-xl">delle opere d'arte che adorneranno ogni dispositivo BSEASAT</p>
                    <p class="font-xl">rendendo i nostri dispositivi unici nel loro genere</p>
                </div>-->
            </div>
            <div class="container col-sm-12 col-md-12 col-xl-6 quadro-sezione-arte" data-aos="slide-left" data-aos-offset="500" data-aos-duration="1500">
            </div>
        </div>
    </div>
</section>