<div
  class="data-form-container container animate__animated animate__fadeInDown"
>
  <h1 class="title-form mat-typography">Inserisci i tuoi dati</h1>
  <mat-vertical-stepper linear #stepper>
    <mat-step [stepControl]="infoPersona">
      <form [formGroup]="infoPersona" (ngSubmit)="termsVisible()">
        <ng-template matStepLabel>Informazioni d'identità</ng-template>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>Nome</mat-label>
          <input
            formControlName="nome"
            matInput
            placeholder="Es: Carlo, Mario, etc."
            required
            name="Nome"
          />
          <mat-error *ngIf="!infoPersona.get('nome')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>Cognome</mat-label>
          <input
            formControlName="cognome"
            matInput
            required
            placeholder="Es: Bianchi, Rossi, etc."
            name="Cognome"
          />
          <mat-error *ngIf="!infoPersona.get('cognome')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>C.F.</mat-label>
          <input
            formControlName="CF"
            matInput
            required
            placeholder="Codice Fiscale"
            name="CodFisc"
          />
          <mat-error *ngIf="!infoPersona.get('CF')?.valid">{{
            getCFError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>E-mail</mat-label>
          <input
            formControlName="email"
            matInput
            required
            placeholder="es: nomeutente@dominio.it"
            name="E-mail"
          />
          <mat-error *ngIf="!infoPersona.get('email')?.valid">{{
            getEmailError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>P.IVA</mat-label>
          <input
            formControlName="pIVA"
            matInput
            placeholder="Partita IVA"
            name="pIva"
            (keyup)="setpecCuRequired()"
          />
          <mat-error *ngIf="!infoPersona.get('pIVA')?.valid">{{
            getpIVAError()
          }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
          <mat-label>PEC o Codice Univoco</mat-label>
          <input
            formControlName="pecCu"
            matInput
            placeholder="Pec o Codice Univoco"
            id="pecCu"
            name="PecCu"
            (keyup)="setpIVARequired()"
          />
          <mat-error *ngIf="!infoPersona.get('pecCu')?.valid">{{
            getRequiredError()
          }}</mat-error>
        </mat-form-field>
        <p class="mat-typography" id="terms">
          <mat-checkbox
            formControlName="terms"
            color="primary"
            (click)="termsHidden()"
          >
            <span>
              Accetto
              <a class="bg-white" target="_blank" href="./privacy.html"
                >termini e condizioni</a
              >
            </span>
          </mat-checkbox>
          <br />
          <span id="termini" color="warn" style="visibility: hidden"
            >Devi accettare i termini di servizio per continuare</span
          >
        </p>
        <p style="position: relative; height: 36px">
          <button
            mat-raised-button
            matStepperNext
            color="primary"
            class="next-btn"
          >
            Next
          </button>
        </p>
      </form>
    </mat-step>
    <mat-step [stepControl]="userMessaggio">
      <form [formGroup]="userMessaggio">
        <ng-template matStepLabel>Messaggio</ng-template>
        <mat-form-field appearance="outline" class="message-area">
          <mat-label>Messaggio</mat-label>
          <textarea
            required
            formControlName="messaggio"
            #messageArea
            rows="7"
            matInput
            maxlength="300"
            (keyup)="lengthCounter()"
          >
          </textarea>
          <div id="the-count">
            <span>{{ textLength }}</span>
            <span id="maximum">/300</span>
          </div>
        </mat-form-field>
        <p class="send-btn">
          <button
            mat-raised-button
            matStepperNext
            color="primary"
            (click)="mailInformation()"
          >
            Invia
          </button>
        </p>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>
<span *ngIf="completamentoOperazione"
  ><app-loading-result
    [completo]="completo"
    [errore]="errore"
    [purchase]="false"
  ></app-loading-result
></span>
