import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-tutorial-page',
  templateUrl: './tutorial-page.component.html',
  styleUrls: ['./tutorial-page.component.scss']
})

export class TutorialPageComponent implements OnInit {
  private videoURL = "https://www.youtube.com/embed/M7WV2UDjscE";
  safeUrl: any;
  
  constructor(private _sanitizer: DomSanitizer) { 
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
  }

  ngOnInit(): void {
  }

}
