<nav id="top-nav" data-aos="fade-in">
  <div class="logo">
    <a href="https://bseasat.com">
      <img rel="preload" src="./assets/images/Bseasat-logo.png" alt="" />
    </a>
  </div>
  <div class="nav-menu">
    <ul id="menu-elements" class="mat-typography menu-desktop">
      <li>
        <a class="white-text medium-font" routerLink="" (click)="scroll('home')"
          >Home</a
        >
      </li>
      <li>
        <a
          class="white-text medium-font"
          routerLink=""
          (click)="scroll('caratteristiche')"
          >Il prodotto</a
        >
      </li>
      <li>
        <a
          class="white-text medium-font"
          routerLink=""
          (click)="scroll('prezzi')"
          >Prezzi</a
        >
      </li>
      <li>
        <a
          class="white-text medium-font"
          routerLink=""
          (click)="scroll('chi-siamo')"
          >Chi siamo</a
        >
      </li>
      <li>
        <a
          class="white-text medium-font"
          routerLink="/tutorial"
          (click)="scrollToTop()"
          >Tutorial Piattaforma</a
        >
      </li>
      <li>
        <a
          class="white-text medium-font"
          routerLink="/contatti"
          (click)="scrollToTop()"
          >Area Contatti</a
        >
      </li>
      <li>
        <a
          class="white-text medium-font"
          routerLink="/info"
          (click)="scrollToTop()"
          >Contattaci</a
        >
      </li>
      <li>
        <a
          class="white-text medium-font"
          routerLink="/lcn"
          (click)="scrollToTop()"
          >Lavora con noi</a
        >
      </li>
      <li class="different">
        <a href="https://kep.it/tracking/" class="yellow medium-font"
          >Localizza Barche</a
        >
      </li>
      <a routerLink="/shopping" (click)="scrollToTop()"
        ><button
          mat-raised-button
          color="accent"
          class="primary-text medium-font"
        >
          Acquista ora
        </button></a
      >
    </ul>
    <button mat-icon-button (click)="snav.toggle()" class="menu-mobile">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</nav>
<mat-sidenav-container>
  <mat-sidenav #snav class="sidenav" position="end" [fixedInViewport]="true">
    <mat-nav-list style="margin-top: 80px" (click)="snav.toggle()">
      <a mat-list-item class="white-text" routerLink="" (click)="scroll('home')"
        >Home</a
      >
      <a
        mat-list-item
        class="white-text"
        routerLink=""
        (click)="scroll('caratteristiche')"
        >Il prodotto</a
      >
      <a
        mat-list-item
        class="white-text"
        routerLink=""
        (click)="scroll('prezzi')"
        >Prezzi</a
      >
      <a
        mat-list-item
        class="white-text"
        routerLink=""
        (click)="scroll('chi-siamo')"
        >Chi siamo</a
      >
      <a
        mat-list-item
        class="white-text"
        routerLink="/tutorial"
        (click)="scrollToTop()"
        >Tutorial Piattaforma</a
      >
      <a
        mat-list-item
        class="white-text"
        routerLink="/contatti"
        (click)="scrollToTop()"
        >Area Contatti</a
      >
      <a
        mat-list-item
        class="white-text"
        routerLink="/info"
        (click)="scrollToTop()"
        >Contattaci</a
      >
      <a
        mat-list-item
        class="white-text"
        routerLink="/lcn"
        (click)="scrollToTop()"
        >Lavora con noi</a
      >
      <a mat-list-item href="https://kep.it/tracking/" class="yellow"
        >Localizza Barche</a
      >
      <a mat-list-item routerLink="/shopping" (click)="scrollToTop()"
        ><button mat-raised-button color="accent" class="primary-text">
          Acquista ora
        </button></a
      >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <app-cookie-popup></app-cookie-popup>
  </mat-sidenav-content>
</mat-sidenav-container>
