import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MailSenderService } from 'src/app/mail-sender.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CouponService } from 'src/app/coupon.service';
import { Coupon } from '../../coupon';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripePersonalService } from 'src/app/stripe.service';
import { switchMap } from 'rxjs/operators';

const require = 'Campo richiesto';
const price = 105;

@Component({
  selector: 'app-form-data-customer',
  templateUrl: './form-data-customer.component.html',
  styleUrls: ['./form-data-customer.component.scss'],
})
export class FormDataCustomerComponent implements OnInit {
  outOfStock = false;
  private prezzo = '0';
  private sconto = 0;
  scontoMetodoPag = 5;
  private coupon = new Coupon();
  private couponApplied = false;
  private cod_coupon_saved = '';

  @ViewChild(StripeCardComponent)
  card!: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#000000',
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '20px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': { color: '#fce883' },
        '::placeholder': { color: '#87bbfd' },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
    hidePostalCode: true,
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'it',
  };

  public payPalConfig?: IPayPalConfig;
  completamentoOperazione = false;
  completo = false;
  errore = false;
  purchase = false;
  shipping = 12;
  firstTime = true;
  changepIVA = false;
  changepecCu = false;
  isLinear = true;
  datiPersona = this.fb.group({
    nome: ['', Validators.required],
    cognome: ['', Validators.required],
    CF: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$'
        ),
      ],
    ],
    email: ['', [Validators.required, Validators.email]],
    pIVA: ['', Validators.pattern('^[0-9]{11}$')],
    pecCu: [''],
    terms: ['', Validators.requiredTrue],
  });

  datiDomicilio = this.fb.group({
    provincia: ['', Validators.required],
    comune: ['', Validators.required],
    CAP: ['', [Validators.required, Validators.pattern('^[0-9]{5}$')]],
    indirizzo: ['', Validators.required],
  });

  datiAcquisto = this.fb.group({
    tipoCliente: ['1', Validators.required],
    quantità: ['1', [Validators.required, Validators.pattern('^[0-9]{1,3}$')]],
    codRivenditore: ['', Validators.pattern('^[A-Z0-9]{4}$')],
    nomeBarca: [''],
    nomiBarche: [''],
    tipoPagamento: ['3', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private mailer: MailSenderService,
    private snackbar: MatSnackBar,
    private couponSearch: CouponService,
    private stripeService: StripeService,
    private stripePersonalService: StripePersonalService
  ) {}

  ngOnInit() {}

  dataCreation() {
    if (this.datiAcquisto.get('tipoCliente')?.value == 1) {
      let pagamento = '';
      let nomiBarche = '';
      switch (this.datiAcquisto.get('tipoPagamento')?.value) {
        case '1':
          pagamento = 'paypal';
          break;
        case '2':
          pagamento = 'bonifico bancario';
          break;
        case '3':
          pagamento = 'carta';
          break;
      }

      if (this.datiAcquisto.get('quantità')?.value == 1) {
        nomiBarche = this.datiAcquisto.get('nomeBarca')?.value;
      } else {
        nomiBarche = this.datiAcquisto.get('nomiBarche')?.value;
      }

      if (this.couponApplied) {
        var data = JSON.stringify({
          nome: this.datiPersona.get('nome')?.value,
          cognome: this.datiPersona.get('cognome')?.value,
          CF: this.datiPersona.get('CF')?.value,
          email: this.datiPersona.get('email')?.value,
          pIVA: this.datiPersona.get('pIVA')?.value,
          pecCu: this.datiPersona.get('pecCu')?.value,
          provincia: this.datiDomicilio.get('provincia')?.value,
          comune: this.datiDomicilio.get('comune')?.value,
          CAP: this.datiDomicilio.get('CAP')?.value,
          indirizzo: this.datiDomicilio.get('indirizzo')?.value,
          quantita: this.datiAcquisto.get('quantità')?.value,
          codRivenditore: this.cod_coupon_saved,
          nomiBarche: nomiBarche,
          prezzo: this.prezzo,
          spedizione: this.shipping,
          tipoPagamento: pagamento,
        });
      } else {
        var data = JSON.stringify({
          nome: this.datiPersona.get('nome')?.value,
          cognome: this.datiPersona.get('cognome')?.value,
          CF: this.datiPersona.get('CF')?.value,
          email: this.datiPersona.get('email')?.value,
          pIVA: this.datiPersona.get('pIVA')?.value,
          pecCu: this.datiPersona.get('pecCu')?.value,
          provincia: this.datiDomicilio.get('provincia')?.value,
          comune: this.datiDomicilio.get('comune')?.value,
          CAP: this.datiDomicilio.get('CAP')?.value,
          indirizzo: this.datiDomicilio.get('indirizzo')?.value,
          quantita: this.datiAcquisto.get('quantità')?.value,
          codRivenditore: this.datiAcquisto.get('codRivenditore')?.value,
          nomiBarche: nomiBarche,
          prezzo: this.prezzo,
          spedizione: this.shipping,
          tipoPagamento: pagamento,
        });
      }
    } else {
      var data = JSON.stringify({
        nome: this.datiPersona.get('nome')?.value,
        cognome: this.datiPersona.get('cognome')?.value,
        CF: this.datiPersona.get('CF')?.value,
        email: this.datiPersona.get('email')?.value,
        pIVA: this.datiPersona.get('pIVA')?.value,
        pecCu: this.datiPersona.get('pecCu')?.value,
        provincia: this.datiDomicilio.get('provincia')?.value,
        comune: this.datiDomicilio.get('comune')?.value,
        CAP: this.datiDomicilio.get('CAP')?.value,
        indirizzo: this.datiDomicilio.get('indirizzo')?.value,
      });
    }
    return data;
  }

  getPrice(paypal: boolean) {
    let quantità = this.datiAcquisto.get('quantità')?.value;
    let tempPrice = quantità * price - this.sconto;
    if (!paypal) tempPrice -= this.scontoMetodoPag * quantità;
    this.prezzo = String(tempPrice);
    return this.prezzo;
  }

  couponControl() {
    if (
      this.datiAcquisto.get('codRivenditore')?.valid &&
      this.datiAcquisto.get('codRivenditore')?.value != ''
    ) {
      this.cod_coupon_saved = this.datiAcquisto.get('codRivenditore')?.value;
      var codToSend = JSON.stringify({
        cod_coupon: this.datiAcquisto.get('codRivenditore')?.value,
      });
      var response = this.couponSearch.searchCoupon(codToSend);
      response.subscribe(
        (x) => {
          this.coupon = x;
          if (this.coupon.valore) {
            this.couponApplied = true;
            document.getElementById('quantità')?.setAttribute('disabled', '');
            this.datiAcquisto.get('quantità')?.setValue(1);
            this.sconto = this.coupon.valore;
            this.snackbar.open(
              'Coupon applicato, dispositivo acquistabile: 1',
              '',
              {
                duration: 5000,
              }
            );
          } else {
            this.snackbar.open('Il codice non esiste', '', {
              duration: 5000,
            });
          }
        },
        (error) => {
          console.log(error);
          this.snackbar.open("Errore durante l'operazione", '', {
            duration: 5000,
          });
        }
      );
    } else {
      this.snackbar.open('Formato non valido', '', {
        duration: 5000,
      });
    }
  }

  getEmailError() {
    if (this.datiPersona.get('email')?.hasError('email')) {
      return 'Formato email non corretto';
    } else if (this.datiPersona.get('email')?.hasError) {
      return require;
    }
    return;
  }

  getpIVAError() {
    if (this.datiPersona.get('pIVA')?.hasError('required')) {
      return require;
    } else if (this.datiPersona.get('pIVA')?.hasError) {
      return 'Il campo deve contenere esattamente 11 caratteri numerici';
    }
    return;
  }

  getCAPError() {
    if (this.datiDomicilio.get('CAP')?.hasError('pattern')) {
      return 'Formato errato';
    } else {
      return require;
    }
  }

  getRequiredError() {
    return require;
  }

  getCFError() {
    if (this.datiPersona.get('CF')?.hasError('required')) {
      return require;
    } else if (this.datiPersona.get('CF')?.hasError) {
      return 'Formato non valido';
    }
    return;
  }

  getQuantitaError() {
    if (this.datiAcquisto.get('quantità')?.hasError('pattern')) {
      return 'Formato errato';
    } else {
      return require;
    }
  }

  setpecCuRequired() {
    if (this.datiPersona.get('pIVA')?.value == '' && this.changepecCu) {
      this.datiPersona.controls['pecCu'].clearValidators();
      this.datiPersona.controls['pecCu'].updateValueAndValidity();
      this.changepecCu = false;
    } else if (this.datiPersona.get('pIVA')?.value != '' && !this.changepecCu) {
      this.datiPersona.controls['pecCu'].setValidators([Validators.required]);
      this.datiPersona.controls['pecCu'].updateValueAndValidity();
      this.changepecCu = true;
    }
  }

  setpIVARequired() {
    if (this.datiPersona.get('pecCu')?.value == '' && this.changepIVA) {
      this.datiPersona.controls['pIVA'].setValidators(
        Validators.pattern('^[0-9]{11}$')
      );
      this.datiPersona.controls['pIVA'].updateValueAndValidity();
      this.changepIVA = false;
    } else if (this.datiPersona.get('pecCu')?.value != '' && !this.changepIVA) {
      this.datiPersona.controls['pIVA'].setValidators([
        Validators.pattern('^[0-9]{11}$'),
        Validators.required,
      ]);
      this.datiPersona.controls['pIVA'].updateValueAndValidity();
      this.changepIVA = true;
    }
  }

  termsVisible() {
    if (this.datiPersona.get('terms')?.hasError('required')) {
      document
        .getElementById('termini')
        ?.setAttribute('style', 'visibility: visible');
    }
  }

  termsHidden() {
    document
      .getElementById('termini')
      ?.setAttribute('style', 'visibility: hidden');
    this.datiPersona.get('terms')?.touched;
  }

  mailBonifico() {
    this.completamentoOperazione = true;
    var response = this.mailer.bonifico(this.dataCreation());
    response.subscribe(
      (x) => {
        this.resultControl(x);
      },
      (error) => {
        this.completo = true;
        this.errore = true;
        console.log(error);
      }
    );
  }

  mailNoleggiatore() {
    this.completamentoOperazione = true;
    var response = this.mailer.noleggiatore(this.dataCreation());
    response.subscribe(
      (x) => {
        this.resultControl(x);
      },
      (error) => {
        this.completo = true;
        this.errore = true;
        console.log(error);
      }
    );
  }

  initConfig(): void {
    if (this.sconto) {
      if (this.firstTime) {
        this.firstTime = false;
        this.payPalConfig = {
          currency: 'EUR',
          clientId:
            'Ac9EfD7OZGNXI7P4KbK8clK4jLWakcLK1hIimiKKI2AeG-JDSb0RHvI1oEZJM1vyr16tv-UJUREqWhVU',
          createOrderOnClient: (data) =>
            <ICreateOrderRequest>{
              intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    currency_code: 'EUR',
                    value: String(price - this.sconto + 12),
                    breakdown: {
                      item_total: {
                        currency_code: 'EUR',
                        value: String(price - this.sconto),
                      },
                      shipping: {
                        value: String(this.shipping),
                        currency_code: 'EUR',
                      },
                    },
                  },
                  items: [
                    {
                      name: 'Dispositivo Bseasat scontato',
                      quantity: '1',
                      category: 'PHYSICAL_GOODS',
                      unit_amount: {
                        currency_code: 'EUR',
                        value: String(price - this.sconto),
                      },
                    },
                  ],
                },
              ],
            },
          advanced: {
            commit: 'true',
          },
          style: {
            label: 'paypal',
            layout: 'vertical',
          },
          onApprove: (data, actions) => {
            this.completamentoOperazione = true;
            actions.order.get().then((details: any) => {});
          },
          onClientAuthorization: (data) => {
            let response = this.mailer.pagamentoCompletato(this.dataCreation());
            response.subscribe(
              (x) => this.resultControl(x),
              (error) => (
                (this.completo = true), (this.errore = true), console.log(error)
              )
            );
          },
          onCancel: (data, actions) => {
            return;
          },
          onError: (err) => {
            this.completo = true;
            this.errore = true;
          },
          onClick: (data, actions) => {
            return;
          },
        };
      } else {
        return;
      }
    } else {
      if (this.firstTime) {
        this.firstTime = false;
        this.payPalConfig = {
          currency: 'EUR',
          clientId:
            'Ac9EfD7OZGNXI7P4KbK8clK4jLWakcLK1hIimiKKI2AeG-JDSb0RHvI1oEZJM1vyr16tv-UJUREqWhVU',
          createOrderOnClient: (data) =>
            <ICreateOrderRequest>{
              intent: 'CAPTURE',
              purchase_units: [
                {
                  amount: {
                    currency_code: 'EUR',
                    value: String(
                      this.datiAcquisto.get('quantità')?.value * price -
                        this.sconto +
                        this.shipping
                    ),
                    breakdown: {
                      item_total: {
                        currency_code: 'EUR',
                        value: String(
                          this.datiAcquisto.get('quantità')?.value * price -
                            this.sconto
                        ),
                      },
                      discount: {
                        currency_code: 'EUR',
                        value: String(this.sconto),
                      },
                      shipping: {
                        value: String(this.shipping),
                        currency_code: 'EUR',
                      },
                    },
                  },
                  items: [
                    {
                      name: 'Dispositivo Bseasat',
                      quantity: this.datiAcquisto.get('quantità')?.value,
                      category: 'PHYSICAL_GOODS',
                      unit_amount: {
                        currency_code: 'EUR',
                        value: String(price),
                      },
                    },
                  ],
                },
              ],
            },
          advanced: {
            commit: 'true',
          },
          style: {
            label: 'paypal',
            layout: 'vertical',
          },
          onApprove: (data, actions) => {
            this.completamentoOperazione = true;
            actions.order.get().then((details: any) => {});
          },
          onClientAuthorization: (data) => {
            let response = this.mailer.pagamentoCompletato(this.dataCreation());
            response.subscribe(
              (x) => (
                (this.completo = true), (this.purchase = true), console.log(x)
              ),
              (error) => (
                (this.completo = true), (this.errore = true), console.log(error)
              )
            );
          },
          onCancel: (data, actions) => {
            return;
          },
          onError: (err) => {
            this.completo = true;
            this.errore = true;
          },
          onClick: (data, actions) => {
            return;
          },
        };
      } else {
        return;
      }
    }
  }

  stripePay(): void {
    this.completamentoOperazione = true;
    this.stripePersonalService
      .createPaymentIntent(this.dataCreation())
      .pipe(
        switchMap((pi) =>
          this.stripeService.confirmCardPayment(pi.client_secret!, {
            payment_method: {
              card: this.card.element,
              billing_details: {
                name:
                  this.datiPersona.get('nome')?.value +
                  ' ' +
                  this.datiPersona.get('cognome')?.value,
                email: this.datiPersona.get('email')?.value,
                address: {
                  city: this.datiDomicilio.get('comune')?.value,
                  postal_code: this.datiDomicilio.get('CAP')?.value,
                  line1: this.datiDomicilio.get('indirizzo')?.value,
                },
              },
            },
          })
        )
      )
      .subscribe((result) => {
        if (result.error) {
          this.completo = true;
          this.errore = true;
          console.log(result.error.message);
        } else {
          if (result.paymentIntent?.status === 'succeeded') {
            let response = this.mailer.pagamentoCompletato(this.dataCreation());
            response.subscribe(
              (x) => (
                (this.completo = true), (this.purchase = true), console.log(x)
              ),
              (error) => (
                (this.completo = true),
                (this.errore = false),
                console.log(error)
              )
            );
          } else {
            this.completo = true;
            this.errore = true;
            console.log(result.paymentIntent?.cancellation_reason);
          }
        }
      });
  }

  resultControl(x: Object) {
    if (x == 'inviato') {
      this.completamentoOperazione = true;
      this.completo = true;
    } else {
      this.completo = true;
      this.errore = true;
      console.log(x);
    }
  }
}
