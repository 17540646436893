import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-result',
  templateUrl: './loading-result.component.html',
  styleUrls: ['./loading-result.component.scss'],
})
export class LoadingResultComponent implements OnInit {
  @Input() completo!: boolean;
  @Input() errore!: boolean;
  @Input() purchase!: boolean;
  constructor() {}

  ngOnInit(): void {}
}
