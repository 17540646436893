<section id="caratteristiche">
  <div class="col-md-12">
    <div class="row mat-typography" style="overflow: hidden">
      <!--<div class="container col-sm-12 col-md-6 foto-box-caratteristiche" data-aos="slide-right" data-aos-duration="1500">
                <div class="frame-foto" >
                    <div class="foto-barca">    
                    </div>
                </div>
            </div>-->
      <div
        class="container col-sm-12 col-md-6 carosello boa-bg"
        data-aos="slide-right"
        data-aos-duration="1500"
        data-aos-offset="400"
      >
        <div
          id="carouselExampleIndicators"
          class="carousel slide ipad"
          data-bs-ride="carousel"
          style="margin-top: 150px; top: -50px"
        >
          <h1 class="white-text" style="text-align: center">
            <span class="primary">BSEASAT</span> special edition
          </h1>
          <h2 class="white-text" style="text-align: center">
            painting by Giovanni Trischitta
          </h2>
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="./dispositivoBseasat3corretto.png"
                class="d-block w-100"
                alt=""
              />
            </div>
            <div class="carousel-item">
              <img
                src="./dispositivoBseasat4.png"
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item">
              <img
                src="./dispositivoBseasat2.png"
                class="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <h3 class="white-text" style="text-align: center; top: -50px">
          *disponibile anche nei colori: blu, arancione e grigio;
        </h3>
      </div>
      <div
        class="container col-sm-12 col-md-6 text-box-caratteristiche"
        data-aos="slide-left"
        data-aos-duration="1500"
        data-aos-offset="400"
      >
        <ul class="text-caratteristiche">
          <span class="title">BSEASAT</span
          ><br /><br />
          <li>
            Massima flessibilità:<br /><span
              >può essere occultato facilmente grazie alle sue ridotte
              dimensioni</span
            ><br /><br />
          </li>
          <li>NON RICHIEDE INSTALLAZIONI<br /><br /></li>
          <li>
            System LongLife:<br /><span
              >lunga durata della batteria<br />(fino ad 1 anno con una
              carica*)</span
            ><br /><br />
          </li>
          <li>
            Ovunque connesso:<br /><span
              >connessione dati Multi-operatore con copertura Europea</span
            ><br /><br />
          </li>
          <li>Dimensioni: 19cm x 3cm<br /><br /></li>
          <span class="clausula"
            >*la durata è solo stimata e potrebbe essere influenzata da diversi
            fattori</span
          >
        </ul>
      </div>
    </div>
  </div>
</section>
