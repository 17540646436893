import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-descrizione-gps',
  templateUrl: './descrizione-gps.component.html',
  styleUrls: ['./descrizione-gps.component.scss']
})
export class DescrizioneGpsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
