import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caratteristiche-prodotto',
  templateUrl: './caratteristiche-prodotto.component.html',
  styleUrls: ['./caratteristiche-prodotto.component.scss']
})
export class CaratteristicheProdottoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
