<section id="chi-siamo" class="mat-typography">
    <div class="col-md-12">
        <div class="row" style="overflow: hidden;">
            <div class=" col-sm-12 col-md-6 text-box-chi-siamo" data-aos="slide-right" data-aos-duration="1500" data-aos-offset="500">
                <p class="text-chi-siamo">
                    <span class="title-chi-siamo">
                        La RGM s.r.l.
                    </span><br><br>
                    Nata nel 1994, con esperienza decennale nel campo dei dispositivi di tracciamento. <br><br>
                    Completamente italiana, la nostra azienda vi offre tutta la sua esperienza per garantirvi un servizio eccellente. <br><br>
                    Cerchiamo da sempre di soddisfare appieno i nostri clienti, e saremo
                    a vostra disposizione per qualsiasi problema o delucidazione.
                </p>
            </div>
            <div class="container col-sm-12 col-md-6"> 
            </div>
        </div>
    </div>
</section>
