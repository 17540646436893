import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sezione-arte',
  templateUrl: './sezione-arte.component.html',
  styleUrls: ['./sezione-arte.component.scss']
})
export class SezioneArteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
