<div class="background-black-trasparent pagina-intera">
  <div class="container box-avvisi" style="text-align: center">
    <span *ngIf="!completo">
      <mat-spinner class="loading-spinner"></mat-spinner>
      <p style="margin-top: 150px">Completamento operazione in corso</p>
    </span>
    <span *ngIf="completo && !errore && !purchase">
      <img
        src="./assets/images/completato.gif"
        alt="completato"
        class="completato"
      />
      <p style="margin-top: 100px">Operazione completata</p>
      <br />
      <button mat-raised-button color="primary" routerLink="">
        Torna alla home
      </button>
    </span>
    <span *ngIf="completo && !errore && purchase">
      <img
        src="./assets/images/completato.gif"
        alt="completato"
        class="completato"
      />
      <p style="margin-top: 100px">Operazione completata</p>
      <p>
        E' stata inviata una e-mail con il riepilogo dell'ordine all'indirizzo
        da lei inserito, per qualsiasi informazione contattateci all'indirizzo
        info@bseasat.com
      </p>
      <br />
      <button mat-raised-button color="primary" routerLink="">
        Torna alla home
      </button>
    </span>
    <span *ngIf="completo && errore">
      <img
        src="./assets/images/cancel.png"
        alt="completato"
        class="completato"
      />
      <p style="margin-top: 100px">
        Operazione fallita, se il problema persiste contattateci all'indirizzo
        info@bseasat.com
      </p>
      <br />
      <button mat-raised-button color="primary" routerLink="">
        Torna alla home
      </button>
    </span>
  </div>
</div>
