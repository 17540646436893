import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StripePersonalService {
  HTTPOptions: Object = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    responseType: 'json',
  };

  constructor(private http: HttpClient) {}

  public createPaymentIntent(data: string): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      'createPaymentIntent.php',
      data,
      this.HTTPOptions
    );
  }
}
