import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { LavoraConNoiComponent } from './lavora-con-noi/lavora-con-noi.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ShoppingPageComponent } from './shopping-page/shopping-page.component';
import { TutorialPageComponent } from './tutorial-page/tutorial-page.component';

const routes: Routes = [
  {path: '', component: HomePageComponent, pathMatch: 'full'},
  {path: 'shopping', component: ShoppingPageComponent},
  {path: 'info', component: InfoPageComponent},
  {path: 'lcn', component: LavoraConNoiComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'contatti', component: ContactPageComponent},
  {path: 'tutorial', component: TutorialPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
